import React, { useEffect, useState } from "react"
import { useApp } from "../../contexts/AppContext"
import { getInstance } from "../../services/instances"
import WarnFlag from "../WarnFlag"

export default function MigrationFlag() {
    const { instance, setInstance } = useApp()

    const [migration, setMigration] = useState()

    useEffect(() => {
        setMigration(instance?.migration)
    }, [instance])

    useEffect(() => {
        if (migration) {
            setTimeout(updateMigration, 5000)
        }
    }, [migration])

    const updateMigration = async () => {
        try {
            const { instance } = await getInstance()
            setInstance(instance)
        } catch (error) {
        }
    }

    if (!migration)
        return <></>

    return (
        <WarnFlag
            text={[
                `Sincronização de ${migration.type == "chat" ? "conversas" : "contatos"} em andamento...`,
                (() => {
                    let total = (migration.take * migration.page) / migration.totalSize
                    if (total > 1)
                        total = 1

                    return `${(total * 100).toFixed(0)}% `
                })()
            ]}
            backgroundColor={"#0195fa"}
            color={"#ffffff"}
        />
    )
}