import React from "react"
import Modal from "../Modal"
import { useApp } from "../../contexts/AppContext"

import "./styles.scss"

export default function ModalInfo({
    open,
    close
}) {
    const { instance } = useApp()

    return (
        <div className="modal-info">
            <Modal open={open} close={close} close-button="false" size="dynamic">
                <div className="modal-resources">
                    {instance &&
                        <div className="modal-body">
                            {[
                                { title: "Contatos", key: "contacts" },
                                { title: "Mensagens", key: "messages" }
                            ].map(({ title, key }) => {
                                const consumption = instance?.[`${key}_consumption`]
                                const limit = instance?.plan?.[`${key}_limit`]
                                let percentage = consumption / limit
                                if (percentage > 1)
                                    percentage = 1

                                return <div className="category">
                                    <div className="category-header">
                                        <p className="category-title">{title}</p>
                                        <p className="category-percentage">{Math.floor(percentage * 100)}%</p>
                                    </div>
                                    <div className="content">
                                        <div className="card">
                                            <p className="text-value">{consumption?.toLocaleString('pt-BR')}</p>
                                            <p className="text-title">Consumo</p>
                                        </div>
                                        <div className="card">
                                            <p className="text-value">{limit?.toLocaleString('pt-BR')}</p>
                                            <p className="text-title">Limite</p>
                                        </div>
                                        <div className="progress-container">
                                            <div
                                                className="progress-inner"
                                                style={{
                                                    width: `${percentage * 100}%`,
                                                    backgroundColor: "#0195FA"
                                                }}
                                            >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    }
                </div>
            </Modal>
        </div>
    )
}