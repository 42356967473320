import { useEffect, useState } from "react"
import Input from "../../Input/Input"

export default function Search({
    chat,
    setChat,
    search,
    setSearch
}) {
    const [found, setFound] = useState([])
    const [selected, setSelected] = useState()

    useEffect(() => {
        if (search && search != "") {
            let found = []
            // console.log(chat)

            for (let item of chat) {
                let { content } = item?.body
                let text = typeof content == "string" ? content : JSON.stringify(content)

                if (text) {
                    text = text.toLowerCase()

                    if (text.indexOf(search?.trim().toLowerCase()) >= 0)
                        found.push(item)
                }
            }
            setFound(() => found)
            setSelected(found.length > 0 ? found.length - 1 : undefined)
        } else {
            setFound(() => [])
            setSelected(undefined)
        }
    }, [search])

    useEffect(() => {
        if (selected != undefined) {
            setChat(chat => chat.map(item => ({ ...item, selected: item._id == found?.[selected]?._id })))
        } else
            setChat(chat => chat.map(item => ({ ...item, selected: false })))
    }, [selected])

    if (search === undefined)
        return <></>

    return (
        <div className="chat-search">
            <div className="input">
                <Input
                    name="search"
                    // label="name"
                    placeholder="Pesquisar nesta conversa"
                    value={search}
                    onChange={e => setSearch(e.target.value?.trim())}
                />
            </div>
            <div>
                <bds-icon
                    size="large"
                    name="close"
                    className="bp-lh-simple bp-c-cloud bp-fs-5 bds-icon bds-icon__size--x-small hydrated"
                    role="img"
                    aria-label="close"
                    theme="outline"
                    onClick={() => setSearch(undefined)}
                ></bds-icon>
            </div>
            {search != "" &&
                <div className="result">
                    <div className="text">
                        {found.length == 0 ?
                            <p>Nenhum resultado encontrado para "<b>{search}</b>"</p> :
                            <p>{selected + 1} de {found.length} resultado{found.length == 1 ? "" : "s"} para "<b>{search}</b>"</p>
                        }
                    </div>

                    <div className="arrows">
                        <bds-icon
                            size="large"
                            name="arrow-up"
                            className="bp-lh-simple bp-c-cloud bp-fs-5 bds-icon bds-icon__size--x-small hydrated"
                            role="img"
                            aria-label="arrow-up"
                            theme="outline"
                            onClick={() => setSelected(selected <= 0 ? found.length - 1 : selected => selected - 1)}
                        ></bds-icon>

                        <bds-icon
                            size="large"
                            name="arrow-down"
                            className="bp-lh-simple bp-c-cloud bp-fs-5 bds-icon bds-icon__size--x-small hydrated"
                            role="img"
                            aria-label="arrow-down"
                            theme="outline"
                            onClick={() => setSelected(selected >= (found.length - 1) ? 0 : selected => selected + 1)}
                        ></bds-icon>
                    </div>
                </div>
            }
        </div>
    )
}