import React, { useContext } from 'react'

import { AppContext } from '../../contexts/AppContext'
import { useSharkPlugins } from '../../contexts/SharkPluginsProvider'

import "./styles.scss"

export default function Copyright() {
    const { t } = useContext(AppContext)

    const { sendAction } = useSharkPlugins()

    return (
        <div className="copyright-container" style={{ gap: 10 }}>
            <div className="copyright-inner">
                <p>{t('developedBy')}</p>

                <a href="https://sharkdev.com.br" target="_blank" onClick={() => {
                    sendAction({
                        type: "event",
                        action: "clickLogoShark",
                        value: 1
                    })
                }}>
                    <img src="https://sharkdev.com.br/static/logos/logo_dark.svg" />
                </a>
            </div>
        </div>
    )
}