import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Header from './components/Header';
import Copyright from '../../components/Copyright';
import CustomTab from '../../CustomTab';
import ResourcesList from '../../components/ResourcesList';
import Resource from '../../components/Resource';

import { AppContext, useApp } from '../../contexts/AppContext';

import "./styles.scss"
import Chat from '../../components/Chat';
import ContactsList from '../../components/ContactsList';
import { getInstance } from '../../services/instances';
import WelcomePage from '../../components/WelcomePage';
import { getChatByIdentity } from '../../services/chat';

const PAGE_ICON = 'plugin';
const BLANK = '_blank';

const Home = () => {
  const { setInstance, plan, setPlan } = useApp()

  const { config } = useApp()

  const [customTab, setCustomTab] = useState(null)

  const [instanceRequest, setInstanceRequest] = useState("requested")

  const [resourceOpened, setResourceOpened] = useState(null)

  const { plan: planAttr } = useParams()

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [contact, setContact] = useState()
  const [chat, setChat] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    startApp()
  }, [])

  useEffect(() => {
    setPlan(planAttr)
  }, [planAttr])

  useEffect(() => {
    if (contact) {
      getChat()
    }
  }, [contact])

  const getChat = async () => {
    try {
      setIsLoading(() => true)

      let { chat } = await getChatByIdentity({ identity: contact?.contact?.identity })

      setChat(chat)
    } catch (error) {
      throw error
    } finally {
      setIsLoading(() => false)
    }
  }

  const startApp = async () => {
    try {
      let { instance } = await getInstance()
      setInstanceRequest("found")
      setInstance(instance)
    } catch (error) {
      if (error?.response?.data?.notFound)
        setInstanceRequest("notFound")
      else
        setInstanceRequest("error")
    }
  }

  // const getResource = async (key, parameter) => {
  //     try {
  //         const result = await IframeMessageProxy.sendMessage({
  //             action: IMPContainer.Actions.SEND_COMMAND,
  //             content: {
  //                 command: {
  //                     method: IMPContainer.CommandMethods.GET,
  //                     uri: `/resources/${key}`
  //                 },
  //                 // destination: IMPContainer.Destinations.BLIP_SERVICE
  //             }
  //         });

  //         return result.response
  //     } catch (error) {
  //         console.error(error)
  //         if (typeof error == "string") {
  //             error = JSON.parse(error)
  //             if (error?.message && typeof error.message == "string")
  //                 error.message = JSON.parse(error.message)
  //         }

  //         console.error("errorrrr", error)

  //         if (error?.code == 67 || error?.message?.reason?.code == 67)
  //             return parameter ? { [parameter]: [] } : {}

  //         throw error
  //     }
  // }

  return (
    <div className="flex" style={{ height: "100vh" }}>
      <div className="home-container relative" style={{ flex: 1 }}>
        {/* {(!resourceOpened || (resourceOpened && config?.showHeaderWithResourceOpen)) &&
                    <Header
                        title={config.customTitle || t('title.homePage')}
                        icon={PAGE_ICON}
                        onClick={() => window.open(t('link.doc'), BLANK)}
                    />
                } */}

        {instanceRequest == "found" &&
          <div className="inner">
            <ContactsList
              selected={contact}
              onSelect={({ contact }) => setContact(contact)}
              full={contact ? false : true}
            />

            {contact &&
              <Chat
                contact={contact.contact}
                chat={chat}
                isLoading={isLoading}
              />
            }
          </div>
        }


        {instanceRequest == "notFound" &&
          <WelcomePage onCreate={({ instance }) => {
            setInstanceRequest("found")
            setInstance(instance)
          }} />
        }

        {(!resourceOpened || (resourceOpened && config?.showCopyrightWithResourceOpen)) &&
          <Copyright />
        }
      </div>


      <CustomTab
        opened={customTab != null}
        setCustomTab={setCustomTab}
        {...customTab}
      />
    </div>
  );
};

Home.propTypes = {};

export default Home;
