import moment from "moment/moment";
import Message from "./Message";
import { useEffect, useState } from "react";
import { executeCommand } from "../../services/blip";

import "./styles.scss"
import Search from "./Search";
import Avatar from "../Avatar";

export default function Chat({
    chat: chatAttr,
    contact,
    isLoading
}) {
    const [chat, setChat] = useState([])
    const [search, setSearch] = useState()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (chat)
            document.querySelector(".chat-messages")?.scrollTo(0, 10000000000)
    }, [contact])

    useEffect(async () => {
        for (let item of chatAttr) {
            for (let key of ["uri", "previewUri"]) {
                if (item?.body?.content?.[key]) {
                    let newAttr = await executeCommand({
                        to: "postmaster@media.msging.net",
                        method: "set",
                        uri: "/refresh-media-uri",
                        type: "text/plain",
                        resource: item.body.content[key]
                    })

                    item.body.content[key] = newAttr
                }
            }
        }

        setChat(chatAttr)
        setSearch(undefined)
        if (!loaded)
            setLoaded(true)
    }, [chatAttr])

    return (
        <div className="chat-container">
            <div className="chat-top">
                <div className="chat-header">
                    <Avatar url={contact.photoUri} />
                    <div className="contact-info">
                        <p className="name">{contact?.name || contact?.identity}</p>
                        <p className="identity">ID: <span>{contact?.identity}</span></p>
                    </div>
                    <div className="actions">
                        <bds-icon
                            size="large"
                            name="search"
                            className="bp-lh-simple bp-c-cloud bp-fs-5 bds-icon bds-icon__size--x-small hydrated"
                            role="img"
                            aria-label="search"
                            theme="outline"
                            onClick={() => setSearch("")}
                        ></bds-icon>

                        {/* <bds-icon
                            size="large"
                            name="download"
                            className="bp-lh-simple bp-c-cloud bp-fs-5 bds-icon bds-icon__size--x-small hydrated"
                            role="img"
                            aria-label="d"
                            theme="outline"
                            onClick={() => generatePDF(`${contact?.identity}_${moment().format("YYYY-MM-DDTHH:mm:ss")}`)}
                        ></bds-icon> */}
                    </div>
                </div>

                <Search
                    chat={chat}
                    setChat={setChat}
                    search={search}
                    setSearch={setSearch}
                />
            </div>

            {isLoading ?
                <div style={{ textAlign: "center", paddingTop: 40 }}>
                    <bds-icon
                        size="brand"
                        name="loading"
                        className="bp-lh-simple bp-c-cloud bp-fs-5 bds-icon bds-icon__size--x-small hydrated"
                        role="img"
                        aria-label="search"
                        theme="outline"
                        lazy="true"
                    ></bds-icon>
                </div> :
                chat.length == 0 ?
                    <div style={{ textAlign: "center", paddingTop: 40 }}>
                        <p>Nenhuma mensagem encontrada</p>
                    </div> :
                    <div className="chat-messages" data-search-opened={search != undefined}>
                        {chat.map((item, index) =>
                            <div key={index}>
                                {item?.reference?.type == "message" &&
                                    <>
                                        <Message
                                            _id={item._id}
                                            origin={item.reference.origin}
                                            {...item.body}
                                            createdAt={item?.reference?.datetime || item?.createdAt}
                                            showDatetime={
                                                moment(item.createdAt).format("DD/MM/YYYY HH:mm") !== moment(chat[index + 1]?.createdAt).format("DD/MM/YYYY HH:mm") ||
                                                item?.reference?.origin !== chat[index + 1]?.reference?.origin
                                            }
                                            selected={item.selected || false}
                                            chat={chat}
                                        />
                                    </>
                                }

                                {item?.reference?.type == "attendanceRedirect" &&
                                    <div className="info">
                                        <p className="info-1">Chatbot {item?.body?.content?.ownerIdentity?.split("@")?.[0]} encaminhou a conversa para atendimento</p>
                                        <p className="info-2">Ticket #{item?.body?.content?.sequentialId}</p>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
            }
        </div>
    )
}