import { useEffect, useState } from "react"
import "./styles.scss"
import Input from "../Input/Input"
import Button from "../Button"
import { getContacts } from "../../services/contacts"
import MigrationFlag from "../MigrationFlag"
import WarnFlag from "../WarnFlag"
import { useApp } from "../../contexts/AppContext"
import { verifyWebhook } from "../../services/blip"
import Avatar from "../Avatar"
import Modal from "../Modal"
import ModalInfo from "../ModalInfo"
import { instanceUpdateWebhook } from "../../services/instances"

export default function ContactsList({
    selected,
    onSelect,
    full
}) {
    const { instance } = useApp()
    const [search, setSearch] = useState()

    const [contacts, setContacts] = useState()

    const [flags, setFlags] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [infoOpen, setInfoOpen] = useState(false)

    const [updating, setUpdating] = useState(false)

    useEffect(() => {
        // fetchAsyncData()
    }, [])

    useEffect(async () => {
        if (instance) {
            verifyFlags()
        }
    }, [instance])

    const fetchAsyncData = async ({ search } = {}) => {
        try {
            setIsLoading(() => true)

            let { contacts } = await getContacts({ search })

            setContacts(contacts)
        } catch (error) {
            throw error
        } finally {
            setIsLoading(() => false)
        }
    }

    const verifyFlags = async () => {
        let flags = [
            instance.migration ? <MigrationFlag /> : undefined,
            ((instance.webhook && await verifyWebhook(instance.webhook)) || updating) ?
                undefined :
                <WarnFlag
                    text={{
                        left: "Webhook desconfigurado, configure-o o mais rápido possível para que seu histórico não fique desatualizado.",
                        right: <div style={{ cursor: "pointer" }} onClick={updating ? () => { } : updateWebhook}>{updating ? "Instando..." : "Instalar"}</div>
                    }}
                    backgroundColor="red"
                    color="#ffffff"
                />
        ].filter(item => item)

        setFlags(() => flags)
    }

    const updateWebhook = async () => {
        if (updating) return;

        setUpdating(() => true)
        await verifyFlags()

        await instanceUpdateWebhook()

        await verifyFlags()
        setUpdating(() => false)
    }

    const onSearch = () => (!search || search?.trim() == "" || search?.trim()?.length <= 3) ? undefined : fetchAsyncData({ search: search?.trim() })

    return (
        <div className="contacts-list-container" data-full={full}>
            <div className="header">
                <div className="search">
                    <div style={{ flex: 1 }}>
                        <form onKeyDown={e => e.key === 'Enter' ? onSearch() : undefined}>
                            <Input
                                name="search"
                                // label="host"
                                placeholder="Pesquise..."
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                waitTime={0}
                            />
                        </form>
                    </div>
                    <div>
                        <Button
                            variant="ghost"
                            icon="search"
                            arrow={false}
                            disabled={!search || search?.trim() == "" || search?.trim()?.length <= 3}
                            onClick={onSearch}
                            isLoading={isLoading}
                        />
                    </div>
                    <div>
                        <Button
                            variant="ghost"
                            icon="info"
                            onClick={() => setInfoOpen(true)}
                        />

                        <ModalInfo open={infoOpen} close={() => setInfoOpen(() => null)} />
                    </div>
                </div>
            </div>

            {flags.map((flag, index) =>
                <div key={index}>
                    {flag}
                </div>
            )}

            {!contacts &&
                <div className="instruction">
                    <bds-icon size="brand" name="search" aria-label="search"></bds-icon>
                    <p>Faça a sua pesquisa</p>
                </div>
            }
            {contacts != undefined &&
                <div className="list" data-flags={flags.filter((item) => item).length}>
                    {(!isLoading && contacts.length == 0) && (
                        <div style={{ textAlign: "center" }}>
                            <p>Nenhum contato encontrado</p>
                        </div>
                    )}
                    {contacts.map((contact) =>
                        <div className="contact" data-selected={selected?.contact?.identity == contact?.contact?.identity} key={contact._id} onClick={() => onSelect({ contact })}>
                            <Avatar url={contact.photoUri} />

                            <div>
                                <p className="name">{contact?.contact?.name}</p>
                                <p className="identity">{contact?.contact?.identity}</p>
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}