import api from "../factory/api"

export const getChatByIdentity = async ({ identity }) => {
    try {
        let { data } = await api.get(`/chat/${identity}`)

        return data
    } catch (error) {
        throw error
    }
}