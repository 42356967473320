import React, { useState } from "react"

import "./styles.scss"
import Button from "../Button"
import { createInstance } from "../../services/instances"
import { useApp } from "../../contexts/AppContext"

export default function WelcomePage({
    onCreate
}) {
    const { plan } = useApp()
    const [isLoading, setIsLoading] = useState(false)

    const start = async () => {
        try {
            setIsLoading(() => true)

            let { instance } = await createInstance({
                plan
            })

            onCreate({ instance })
        } catch (error) {
            throw error
        } finally {
            setIsLoading(() => false)
        }
    }


    return (
        <div className="welcome-page">
            <p className="title">Bem-vindo ao <b>Histórico Permanente</b></p>
            <p>Com a nossa extensão você terá:</p>
            <div>
                <ul>
                    <li>Histórico permanente das suas conversas, iniciadas 3 meses atrás</li>
                    <li>Todos os seus contatos salvos</li>
                    <li>Pesquisa facilitada de contato e conversa</li>
                </ul>
            </div>
            <p>Ao clicar no botão abaixo, você concorda que façamos um backup de todos os seus contatos e conversas, assim como que instalemos um webhook no seu roteador.</p>

            <Button
                onClick={isLoading ? () => null : start}
                disabled={isLoading}
                isLoading={isLoading}
            >Instalar</Button>
        </div>
    )
}