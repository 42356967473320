import axios from 'axios';
import settings from '../config';
import { getApplicationDataAsync } from '../services/application-service';
import { generateAuthorizationToken } from '../services/utils';

const HEADER_CONTENT = 'Content-Type';
const TYPE_JSON = 'application/json; charset=utf-8';

const API = () => {
    const api = axios.create({
        baseURL: settings.api.url,
        // headers: {
        //     Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2IyMDZkZmUxODRlMWMzMTcyMGQ1MWQiLCJpYXQiOjE2NzI2MTE1NTF9.WvF3xRRQa9TeVD6vORKJ7_lhbyv-VT1laDaOkYQVgoQ"
        // }
    });

    api.interceptors.request.use(async (config) => {
        const headerConfig = config;
        headerConfig.headers[HEADER_CONTENT] = TYPE_JSON;

        let appInfo = await getApplicationDataAsync()

        headerConfig.headers.Authorization = generateAuthorizationToken(appInfo)
        headerConfig.headers.tenantId = appInfo.tenantId

        return headerConfig;
    });

    return api;
};

export default API();
