import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import RoutesPath from '../constants/routes-path';

import HomePage from '../pages/Home';
import ExamplePage from '../pages/Example';

import Analytics from './Analytics';
import SettingsPage from '../pages/Settings';

const Routes = () => (
    <BrowserRouter>
        <Analytics>
            <Switch>
                <Route exact path={"/:plan"} component={HomePage} />
            </Switch>
        </Analytics>
    </BrowserRouter>
);

export default Routes;
