import moment from "moment/moment"
import "./styles.scss"
import AudioPlayer from "../../AudioPlayer"
import { useEffect, useRef, useState } from "react"
import { isElementOfType } from "react-dom/test-utils";

export default function Message({
    _id,
    origin,
    content,
    createdAt,
    showDatetime,
    selected,
    chat = []
}) {
    const [inReplyTo, setInReplyTo] = useState(null)

    useEffect(() => {
        let type = getMessageType(content)

        if (type == "reply") {
            setInReplyTo(chat.find((item) => item?.body?.id == content?.inReplyTo?.id) || null)
        }
    }, [])

    useEffect(() => {
        if (selected) {
            var element = document.querySelector(`.message-${_id}`);
            if (element)
                element?.scrollIntoView({ behavior: "smooth" })
        }
    }, [selected])

    const getMessageType = (content) => {
        let text =
            (typeof content == "string" ? content : undefined) ||
            content?.text

        if (typeof content == "object") {
            if (content.options)
                return "menu"
            if (content.template)
                return "template"
            else if (content.interactive)
                return "interactive"
            else if (content.type) {
                if (content.type.indexOf("image") == 0 && (content.previewUri || content.uri))
                    return "image"
                else if (content.type.indexOf("audio") == 0 && (content.previewUri || content.uri))
                    return "audio"
                else if (/*content.type.indexOf("application") == 0 && */(content.previewUri || content.uri))
                    return "file"
            } else if (content.replied)
                return "reply"
        }

        if (text)
            return "text"
        return "not-supported"
    }

    const Content = ({ type, content }) => {
        let text =
            (typeof content == "string" ? content : undefined) ||
            content?.text

        let MessageComponent = ({ text }) => <p className="message" dangerouslySetInnerHTML={{ __html: text }}></p>

        const types = {
            "not-supported": <div><p>Conteúdo não suportado</p></div>,
            template: <MessageComponent text={`Modelo de mensagem: ${content?.template?.name}`} />,
            interactive:
                <div className="interactive-message">
                    <div className="header">{content?.interactive?.header?.text}</div>
                    <div className="body">{content?.interactive?.body?.text}</div>
                    <div className="divider"></div>
                    <div className="action">{content?.interactive?.action?.button}</div>
                </div>
            ,
            "interactive-list":
                <div className="interactive-list">
                    {content?.interactive?.action?.button && <p className="title">{content?.interactive?.action?.button}</p>}

                    <div className="list">
                        {content?.interactive?.action?.sections?.map((section, index1) =>
                            <div key={index1}>
                                {section?.rows?.map((row, index2) =>
                                    <div key={`${index1}-${index2}`}>
                                        <div className="row">
                                            {row?.title}
                                        </div>
                                        {((section?.rows?.length - 1) > index2) && <div className="divider"></div>}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>,
            image:
                <div className="image-message">
                    <img className="image-preview" src={content.uri || content.previewUri} onClick={() => window.open(content.uri || content.previewUri, "_blank")} />
                    {content.title && <p className="title" dangerouslySetInnerHTML={{ __html: content.title }} onClick={() => window.open(content.uri || content.previewUri, "_blank")}></p>}
                    {content.text && <p className="text" dangerouslySetInnerHTML={{ __html: content.text }}></p>}
                </div>
            ,
            audio:
                <AudioPlayer
                    src={content.previewUri}
                    color={origin == "user" ? "dark" : "white"}
                />
            ,
            file:
                <div className="file-message">
                    {content.title && <p className="title" dangerouslySetInnerHTML={{ __html: content.title }} onClick={() => window.open(content.previewUri || content.uri, "_blank")}></p>}
                    {content.text && <p className="text" dangerouslySetInnerHTML={{ __html: content.text }} onClick={() => window.open(content.previewUri || content.uri, "_blank")}></p>}
                </div>
            ,
            text: <MessageComponent text={text} />,
            reply:
                <div className="reply-message">
                    <div className="header">
                        <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.62 9.9L17.62 7.3C17.5609 7.22121 17.4869 7.15483 17.4021 7.10464C17.3174 7.05446 17.2236 7.02147 17.1261 7.00754C17.0286 6.99361 16.9293 6.99902 16.8339 7.02346C16.7385 7.04791 16.6488 7.09091 16.57 7.15C16.4912 7.20909 16.4248 7.28313 16.3746 7.36788C16.3245 7.45263 16.2915 7.54643 16.2775 7.64393C16.2636 7.74144 16.269 7.84073 16.2935 7.93614C16.3179 8.03155 16.3609 8.12121 16.42 8.2L17.51 9.65H8.11C7.58302 9.63537 7.05835 9.72506 6.56615 9.91391C6.07395 10.1028 5.62394 10.387 5.24199 10.7504C4.86003 11.1138 4.55368 11.549 4.34053 12.0312C4.12739 12.5134 4.01166 13.0329 4 13.56V13.7C4.01036 14.2279 4.12513 14.7485 4.3377 15.2319C4.55026 15.7152 4.85642 16.1517 5.2385 16.5161C5.62058 16.8805 6.07104 17.1657 6.56388 17.3551C7.05672 17.5446 7.5822 17.6346 8.11 17.62H11.67C11.8689 17.62 12.0597 17.541 12.2003 17.4003C12.341 17.2597 12.42 17.0689 12.42 16.87C12.42 16.6711 12.341 16.4803 12.2003 16.3397C12.0597 16.199 11.8689 16.12 11.67 16.12H8.11C7.44321 16.1443 6.79398 15.9032 6.3047 15.4496C5.81542 14.9959 5.52603 14.3667 5.5 13.7V13.56C5.52861 12.895 5.81912 12.2684 6.30811 11.8169C6.79711 11.3654 7.44487 11.1256 8.11 11.15H17.8L16.46 12.92C16.4009 12.9988 16.3579 13.0885 16.3335 13.1839C16.309 13.2793 16.3036 13.3786 16.3175 13.4761C16.3315 13.5736 16.3645 13.6674 16.4146 13.7521C16.4648 13.8369 16.5312 13.9109 16.61 13.97C16.7654 14.0742 16.9541 14.1166 17.1391 14.0889C17.3241 14.0611 17.4921 13.9652 17.61 13.82L19.61 11.22C19.7543 11.0307 19.8332 10.7997 19.835 10.5617C19.8368 10.3237 19.7614 10.0915 19.62 9.9V9.9Z" fill="currentColor" />
                        </svg>
                        <p>Resposta</p>
                    </div>
                    {inReplyTo &&
                        <div className="in-reply-to">
                            <Content
                                type={getMessageType(inReplyTo?.body?.content)}
                                content={inReplyTo?.body?.content}
                            />
                        </div>
                    }
                    <MessageComponent text={content?.replied?.value} />
                </div>
        }

        return types?.[type || "not-supported"] || types?.["not-supported"]
    }

    const MessageContainer = ({
        _id,
        origin,
        selected,
        typeMessage,
        content,
        showDatetime,
        createdAt
    }) => (
        <div className={`message-container message-${_id}`} data-origin={origin == "user" ? "received" : "sent"}>
            {typeMessage == "menu" ?
                <div className="message-menu">
                    {content?.options?.map((option, index) =>
                        <div className="option" key={index}>
                            <p>{option.text}</p>
                        </div>
                    )}
                </div>
                :
                <div className="message-inner" data-selected={selected}>
                    <Content type={typeMessage} content={content} />
                </div>
            }
            <div className="message-info">
                {showDatetime == true && <p className="datetime">{moment(createdAt).format("DD/MM/YYYY HH:mm")}</p>}
            </div>
        </div>
    )

    const typeMessage = getMessageType(content)

    if (typeMessage == "menu")
        return (
            <div>
                {content.text &&
                    <MessageContainer
                        _id={_id}
                        origin={origin}
                        selected={selected}
                        typeMessage={"text"}
                        content={content}
                        showDatetime={false}
                        createdAt={createdAt}
                    />
                }

                <MessageContainer
                    _id={_id}
                    origin={origin}
                    selected={selected}
                    typeMessage={"menu"}
                    content={content}
                    showDatetime={showDatetime}
                    createdAt={createdAt}
                />
            </div>
        )

    if (typeMessage == "interactive")
        return (
            <div>
                <MessageContainer
                    _id={_id}
                    origin={origin}
                    selected={selected}
                    typeMessage={typeMessage}
                    content={content}
                    showDatetime={false}
                    createdAt={createdAt}
                />
                <MessageContainer
                    _id={_id}
                    origin={origin}
                    selected={selected}
                    typeMessage={"interactive-list"}
                    content={content}
                    showDatetime={showDatetime}
                    createdAt={createdAt}
                />
            </div>
        )

    return (
        <MessageContainer
            _id={_id}
            origin={origin}
            selected={selected}
            typeMessage={typeMessage}
            content={content}
            showDatetime={showDatetime}
            createdAt={createdAt}
        />
    )

    // return (
    //     <div className={`message-container message-${_id}`} data-origin={origin == "user" ? "received" : "sent"}>
    //         <div className="message-inner" data-selected={selected}>
    //             <Content type={typeMessage} content={content} />
    //         </div>
    //         <div className="message-info">
    //             {showDatetime == true && <p className="datetime">{moment(createdAt).format("DD/MM/YYYY HH:mm")}</p>}
    //         </div>
    //     </div>
    // )
}