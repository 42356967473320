import React from "react"

import "./styles.scss"

export default function WarnFlag({
    text,
    backgroundColor,
    color
}) {
    return (
        <div className="warn-flag" style={{ backgroundColor, color }}>
            {(Array.isArray(text) ? text : [text]).map((txt, index) =>
                <div key={index} className="row">
                    {typeof txt == "object" ?
                        <>
                            <div>
                                <p>{txt.left}</p>
                            </div>
                            <div>
                                <p>{txt.right}</p>
                            </div>
                        </>
                        :
                        <p>{txt}</p>
                    }
                </div>
            )}
        </div>
    )
}