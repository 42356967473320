import React, { createContext, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { getApplicationDataAsync } from "../services/application-service";
import { getLoggedUserAsync } from "../services/user-service";
import { useSharkPlugins } from "./SharkPluginsProvider";
import { jwtDecode } from 'jwt-decode';
import { authorizationEncode } from "../services/blip";
import apiBlip from "../services/blip.api"

export const AppContext = createContext();

export function AppProvider({ children }) {
    const { t, i18n } = useTranslation();

    const { getQueryString } = useSharkPlugins();

    const [appInfo, setAppInfo] = useState({});
    const [user, setUser] = useState({});
    const [language, setLanguage] = useState('');

    const [instance, setInstance] = useState()

    const [plan, setPlan] = useState()

    useEffect(() => {
        getAppDataAsync()
    }, [])

    const getAppDataAsync = async () => {
        const app = await getApplicationDataAsync();
        setAppInfo(app);

        // console.log("appInfo", app);

        const loggedUser = await getLoggedUserAsync();
        setUser(loggedUser);
        // console.log("loggedUser", loggedUser)
        // alert(i18n.language)
        setLanguage(i18n.language);
    };

    const getConfig = () => {
        let defaultConfig = {
            customTitle: null,
            resourceName: "resourcesManagerPlugin",
            showHeaderWithResourceOpen: true,
            showCopyrightWithResourceOpen: true,
            showDocs: true,
            showImportResource: true,
            showImportItems: true,
            showExport: true,
            showCreateResource: true,
            showEditResource: true,
            showDeleteResource: true,
            showCopyResource: true,
            typeResourceDefault: "",
            showTypeResourceSelector: true,
            defaultResourceOpened: null,
            showBackToResourcesFromResource: true,
            resourcesTypesAvailable: [
                "collection",
                "single"
            ],
            fieldsTypesAvailable: [
                "text",
                "textarea",
                "select",
                "text-list",
                "object-list",
                "switch",
                "file",
                "select-http"
            ]
        }

        let query = {};
        try {
            query = getQueryString() || {}

            Object.keys(query).map(key => {
                if (query[key] == "false")
                    query[key] = false
                else if (query[key] == "true")
                    query[key] = true
            })
        } catch { }

        let Authorization;

        // console.log(query)

        if (query.appData) {
            try {
                // console.log(query.appData)
                let decoded;

                for (let appData of [query.appData, `eyJhbGciOiJIUzI1NiJ9.${query.appData}.Pr6HclWCt-DJmA9U6Wn_J-oGgi0RQAGP7OyzDbx3l5Y`]) {
                    try {
                        decoded = jwtDecode(appData)
                        // console.log("decoded", decoded)
                        if (decoded)
                            break;
                    } catch { }
                }

                // console.log(decoded)

                let { routerData } = decoded
                let { shortName, accessKey } = routerData || {}

                if (shortName && accessKey) {
                    Authorization = authorizationEncode(shortName, accessKey)
                }
            } catch (error) {
                // console.error(error)
            }
        } else if (query.shortName && query.accessKey) {
            Authorization = authorizationEncode(query.shortName, query.accessKey)
        }

        if (Authorization)
            apiBlip.defaults.headers.common['Authorization'] = Authorization;

        // console.log("query", query)

        return {
            ...defaultConfig,
            ...query
        }
    }

    return (
        <AppContext.Provider
            value={{
                t,
                i18n,
                appInfo,
                user,
                language,
                config: getConfig(),
                instance, setInstance,
                plan, setPlan
            }}>
            {children}
        </AppContext.Provider>
    );
};


export function useApp() {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useApp must be used within an AppProvider');
    }
    return context;
}
