import api from "../factory/api"

export const getInstance = async () => {
    try {
        let { data } = await api.get("/instances")

        return data
    } catch (error) {
        throw error
    }
}

export const createInstance = async (body = {}) => {
    try {
        let { data } = await api.post("/instances", body)

        return data
    } catch (error) {
        throw error
    }
}

export const instanceUpdateWebhook = async () => {
    try {
        let { data } = await api.put("/instances/update/webhook", {})

        return data
    } catch (error) {
        throw error
    }
}