import api from "../factory/api"

export const getContacts = async ({ search } = {}) => {
    try {
        let { data } = await api.get("/contacts", { params: { search } })

        return data
    } catch (error) {
        throw error
    }
}