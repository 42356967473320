import React, { useEffect, useRef, useState } from 'react';

import { convertSecondsToMinutesSeconds } from "../../services/utils"

import "./styles.scss"

function AudioPlayer({
    src,
    color = "white"
}) {
    const audioRef = useRef();
    const [playing, setPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [speed, setSpeed] = useState(0);

    const speeds = [1, 1.5, 2]

    useEffect(() => {
        if (progress == 100 && playing) {
            setPlaying(false)
            setProgress(0)
            audioRef.current.currentTime = 0
        }
    }, [progress])

    const togglePlayPause = () => {
        if (playing) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setPlaying(!playing);
    };

    const updateProgress = () => {
        setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
    };

    const changeSpeed = (newSpeed) => {
        audioRef.current.playbackRate = speeds[newSpeed];
        setSpeed(newSpeed);
    };

    return (
        <div className="audio-container" data-color={color}>
            <audio ref={audioRef} onTimeUpdate={updateProgress} src={src}>
                Seu navegador não suporta o elemento de áudio.
            </audio>

            <div className="audio-inner">
                <button
                    className="play-button"
                    onClick={togglePlayPause}>
                    {playing ?
                        <svg
                            width="12px"
                            height="18px"
                            viewBox="0 0 12 18"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            class="audio-player-button">
                            <g id="Pause" stroke="none" stroke-width="1" fill-rule="nonzero">
                                <path
                                    d="M4.61538462,17.351355 C4.61538462,17.7095549 4.33986449,18 4,18 L0.615384615,18 C0.275520128,18 0,17.7095885 0,17.351355 L0,0.648645015 C0,0.290411481 0.275520128,0 0.615384615,0 L4,0 C4.33986449,0 4.61538462,0.290411481 4.61538462,0.648645015 L4.61538462,17.351355 Z" id="Shape"></path> <path d="M12,17.351355 C12,17.7095549 11.7244799,18 11.3846154,18 L8,18 C7.66013551,18 7.38461538,17.7095885 7.38461538,17.351355 L7.38461538,0.648645015 C7.38461538,0.290411481 7.66013551,0 8,0 L11.3846154,0 C11.7244799,0 12,0.290411481 12,0.648645015 L12,17.351355 Z"
                                />
                            </g>
                        </svg> :
                        <svg
                            width="14px"
                            height="18px"
                            viewBox="0 0 14 18"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            className="play-button"
                        >
                            <g id="Play" stroke="none" strokeWidth="1" fillRule="evenodd">
                                <path
                                    d="M13.3805199,7.82862117 C14.2064934,8.35374536 14.2064934,9.64760806 13.3805199,10.1700254 L1.21459333,17.8790651 C0.677273234,18.2201251 0,17.7992137 0,17.1238607 L0,0.876139285 C0,0.200786266 0.677273234,-0.220125134 1.21459333,0.120934907 L13.3805199,7.82862117 Z"
                                />
                            </g>
                        </svg>
                    }
                </button>

                <div className="center">
                    <progress className="progress" value={progress} max="100"></progress>
                    <div className="times">
                        <p>{convertSecondsToMinutesSeconds(audioRef?.current?.currentTime || 0)}</p>
                        <p>{convertSecondsToMinutesSeconds(audioRef?.current?.duration || 0)}</p>
                    </div>
                </div>
                <button
                    className="speed"
                    onClick={() => changeSpeed(speed >= (speeds.length - 1) ? 0 : speed + 1)}>{speeds[speed]}x</button>
            </div>
        </div>
    );
}

export default AudioPlayer;
